import React, { Fragment } from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Typography, TextField, Button } from "@material-ui/core";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";

import Logo from "../../Assets/Logo.png";
import useSetState from "../../Utils/StateCustomHook";
import FormikField from "../../Components/FormikField";
import { planExpirationApi } from "../../Network/Apis";
import { setCustomerId, setOperatorId, getCustomerId } from "../../Utils/Storage";
import MadeWithLove from "../../Components/MadeWithLove";

import Styles from "./styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps extends WithStyles<typeof Styles>, RouteComponentProps {}

const SigninPage: React.FunctionComponent<IProps> = ({ classes }) => {
    const history = useHistory();

    React.useEffect(() => {
        if (getCustomerId()) {
            history.replace("/");
        }
    }, []);

    const [state, setState] = useSetState<{ apiError: unknown }>({
        apiError: {},
    });

    const validationSchema = yup.object().shape({
        customerId: yup.string().required("Username has to be valid"),
        operatorId: yup.string().required("Operator Id has to be valid"),
    });

    const formikInitialValues = { customerId: "", operatorId: "" };

    const onSubmit = async (
        values: typeof formikInitialValues,
        formikhelpers: FormikHelpers<typeof formikInitialValues>,
    ) => {
        try {
            setState({ apiError: undefined });
            //check if credentials are correct
            // save all the data and move to dashboard

            const response = await planExpirationApi(values.customerId, values.operatorId);
            //Logged in
            if (response.status.err_code > 0) {
                //error occurred
                // eslint-disable-next-line no-console
                console.log("error", response);
                formikhelpers.setSubmitting(false);
                formikhelpers.setValues({ customerId: "", operatorId: "" });
                setState({ apiError: response.status.err_msg });
                return;
            }
            //store all the data
            setCustomerId(values.customerId);
            setOperatorId(values.operatorId);
            formikhelpers.setSubmitting(false);
            //navigate to dashboard
            history.replace("/");
            return;
        } catch (error) {
            formikhelpers.setSubmitting(false);
            // formikhelpers.setValues({ operatorId: "", customerId: "" });
            setState({ apiError: "Something went wrong" });
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };

    return (
        <div className={classes.container}>
            <img src={Logo} className={classes.logoImage} />
            <Typography className={classes.header} variant={"h6"}>
                Enter your Username and Operator ID
            </Typography>
            <Formik initialValues={formikInitialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ submitForm, isSubmitting, errors }) => {
                    return (
                        <Fragment>
                            <FormikField
                                Component={TextField}
                                name="customerId"
                                className={classes.inputField}
                                label="Customer Id"
                                disabled={isSubmitting}
                                helperText={errors.customerId}
                                error={!!errors.customerId}
                            />
                            <FormikField
                                Component={TextField}
                                name="operatorId"
                                className={classes.inputField2}
                                label="Operator ID (ex: BBNL_OP930)"
                                disabled={isSubmitting}
                                helperText={errors.operatorId}
                                error={!!errors.operatorId}
                            />
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                className={classes.button}
                                onClick={submitForm}
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </Fragment>
                    );
                }}
            </Formik>
            <Typography variant="body1" color="error" align="center" className={classes.apiErrorText}>
                {typeof state.apiError === "string" ? state.apiError : JSON.stringify(state.apiError)}
            </Typography>
            <MadeWithLove />
        </div>
    );
};
export default withStyles(Styles)(SigninPage);
