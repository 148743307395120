import qs from "qs";

import Router from "../router";
import { paymentHistoryRoute } from "../ApiRoutes";

export interface PaymentInterface {
    plan_rate: string;
    expirydate: string;
    renewdate: string;
    payment_date: string;
    discount_amt: string;
    other_charges: string;
    paid_amt: string;
    pymt_mode: string;
    trans_status: string;
    renew_status: string;
    planname: string;
    bill_num: string;
    billnumber: string;
    remark: string;
    trans_id: string;
    gatewaytransid: string;
    id: string;
}

export interface PaymentHistoryResponseInterface {
    error: number;
    result: PaymentInterface[];
    resultcount: string;
}

export function paymentHistoryApi(operatorId: string, customerId: string) {
    return Router.request<PaymentHistoryResponseInterface>({
        method: "post",
        url: paymentHistoryRoute,
        data: qs.stringify({
            apiopid: operatorId,
            apiuserid: customerId,
        }),
    });
}
