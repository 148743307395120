import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (_theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
        },
        logoImage: {
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: 64,
            marginBottom: 24,
        },
        header: {
            margin: "24px auto",
        },
        inputField: {
            margin: "24px 24px",
        },
        inputField2: {
            margin: "16px 24px",
        },
        button: {
            margin: "0px auto",
        },
        apiErrorText: {
            margin: "16px auto",
        },
    });

export default Styles;
