import React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

import Styles from "./styles";

interface FullPageActionProps {
    onClick: () => void;
}
const FullPageAction: React.FunctionComponent<FullPageActionProps & WithStyles<typeof Styles>> = ({
    classes,
    onClick,
}) => {
    React.useEffect(() => {
        document.body.classList.add(classes.overflowHidden);

        return () => document.body.classList.remove(classes.overflowHidden);
    }, []);

    return (
        <div className={classes.container}>
            <Typography color="error" variant="body1">
                Something went wrong!
            </Typography>
            <Button className={classes.button} color="primary" variant="contained" onClick={onClick}>
                Reload
            </Button>
        </div>
    );
};
export default withStyles(Styles)(FullPageAction);
