import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { DashboardPage, NotFoundPage, SigninPage } from "./Pages";
import CustomTheme from "./Theme";

const App: React.FC = (): JSX.Element => {
    return (
        <Fragment>
            <CssBaseline />
            <MuiThemeProvider theme={CustomTheme}>
                <Router>
                    <Switch>
                        <Route path="/404" component={NotFoundPage} />
                        <Route path="/signin" exact component={SigninPage} />
                        <Route path="/" exact component={DashboardPage} />
                        <Redirect path="/*" to="/404" />
                    </Switch>
                </Router>
            </MuiThemeProvider>
        </Fragment>
    );
};

export default hot(module)(App);
