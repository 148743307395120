import qs from "qs";

import Router from "../router";
import { BaseURL2, dataUsageRoute } from "../ApiRoutes";
export interface DataUsageResponseInterface {
    error: number;
    result: {
        upload: string;
        download: string;
        total: string;
        balance: string;
    };
}

export function dataUsageApi(operatorId: string, customerId: string, fromDate: string, toDate: string) {
    return Router.request<DataUsageResponseInterface>({
        method: "post",
        baseURL: BaseURL2,
        url: dataUsageRoute,
        data: qs.stringify({
            apiopid: operatorId,
            apiuserid: customerId,
            fromdt: fromDate,
            todt: toDate,
        }),
    });
}
