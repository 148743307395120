import axios, { Method, AxiosRequestConfig } from "axios";

import { BaseURL, ProxyURL } from "./ApiRoutes";

interface IRequest extends AxiosRequestConfig {
    url?: string;
    method: Method;
}

class Router {
    public POST: Method = "post";
    public GET: Method = "get";
    public PUT: Method = "put";
    public DELETE: Method = "DELETE";

    private headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        // "User-Agent": "Dalvik/2.1.0 (Linux; U; Android 10; Android SDK built for x86 Build/QSR1.190920.001)",
    };

    public async request<T>(params: IRequest) {
        const response = await axios.request<T>({
            ...params,
            headers: { ...this.headers, ...params?.headers, "x-forward-url": params.baseURL || BaseURL },
            baseURL: ProxyURL,
        });
        return response.data;
    }
}

export default new Router();
