import React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

import Styles from "./styles";

const PageLoader: React.FunctionComponent<WithStyles<typeof Styles>> = ({ classes }) => {
    React.useEffect(() => {
        document.body.classList.add(classes.overflowHidden);

        return () => document.body.classList.remove(classes.overflowHidden);
    }, []);
    return (
        <div className={classes.container}>
            <CircularProgress />
        </div>
    );
};
export default withStyles(Styles)(PageLoader);
