export function setCustomerId(customerId: string) {
    localStorage.setItem("CustomerId", customerId);
}

export function getCustomerId() {
    return localStorage.getItem("CustomerId");
}

export function setCustomerName(customerId: string) {
    localStorage.setItem("CustomerName", customerId);
}

export function getCustomerName() {
    return localStorage.getItem("CustomerName");
}

export function setCustomerMobile(customerId: string) {
    localStorage.setItem("CustomerMobile", customerId);
}

export function getCustomerMobile() {
    return localStorage.getItem("CustomerMobile");
}
