import React, { Fragment } from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography, Table, TableBody, TableCell, TableRow, Card } from "@material-ui/core";
import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";

import { getCustomerId, getOperatorId } from "../../Utils/Storage";
import PageLoader from "../../Components/PageLoader";
import useSetState from "../../Utils/StateCustomHook";
import {
    DataUsageResponseInterface,
    PlanExpirationResponseInterface,
    PaymentInterface,
    dataUsageApi,
    paymentHistoryApi,
    planExpirationApi,
} from "../../Network/Apis";
import FullPageAction from "../../Components/FullPageAction";
import MadeWithLove from "../../Components/MadeWithLove";

import Styles from "./styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps extends WithStyles<typeof Styles>, RouteComponentProps {}
const DashboardPage: React.FunctionComponent<IProps> = ({ classes }) => {
    const history = useHistory();

    const [state, setState] = useSetState<{
        dataUsage: DataUsageResponseInterface["result"] & { fromDate: string; toDate: string };
        planExpiration: Pick<PlanExpirationResponseInterface, "date" | "noofDays">;
        payments: PaymentInterface[];
        isApiCallComplete1: boolean;
        isApiCallComplete2: boolean;
        isApiCallComplete3: boolean;
        apiErrorRequireReload: boolean;
    }>({
        dataUsage: { download: "", balance: "", total: "", upload: "", fromDate: "", toDate: "" },
        planExpiration: { date: "", noofDays: -1 },
        payments: [],
        isApiCallComplete1: false,
        isApiCallComplete2: false,
        isApiCallComplete3: false,
        apiErrorRequireReload: false,
    });

    const logout = () => {
        //logout
        localStorage.clear();
        history.push("/signin");
    };

    React.useEffect(() => {
        // check if logged in
        const customerId = getCustomerId();
        const operatorId = getOperatorId();
        if (!customerId || !operatorId) {
            logout();
            return;
        }
        //all API calls

        paymentHistoryApi(operatorId, customerId)
            .then((response) => {
                setState({ isApiCallComplete2: true, payments: response.result });
            })
            .catch(() => {
                //handle page load error
                //ask to reload page
                setState({ apiErrorRequireReload: true });
            });

        planExpirationApi(customerId, operatorId)
            .then((response) => {
                dayjs.extend(CustomParseFormat);
                const dataUsageFromDate = dayjs(response.date, "DD-MM-YYYY").subtract(1, "month").format("DD-MM-YYYY");
                const dataUsageToDate = response.date;
                setState({ isApiCallComplete3: true, planExpiration: response });
                //Call Data Usage API
                dataUsageApi(operatorId, customerId, dataUsageFromDate, dataUsageToDate)
                    .then((response) => {
                        setState({
                            isApiCallComplete1: true,
                            dataUsage: { ...response.result, fromDate: dataUsageFromDate, toDate: dataUsageToDate },
                        });
                    })
                    .catch(() => {
                        //handle page load error
                        //ask to reload page
                        setState({ apiErrorRequireReload: true });
                    });
            })
            .catch(() => {
                //handle page load error
                //ask to reload page
                setState({ apiErrorRequireReload: true });
            });
    }, []);

    const {
        dataUsage,
        planExpiration,
        payments,
        isApiCallComplete1,
        isApiCallComplete2,
        isApiCallComplete3,
        apiErrorRequireReload,
    } = state;
    return (
        <Fragment>
            {apiErrorRequireReload && <FullPageAction onClick={() => window.location.reload()} />}

            {(!isApiCallComplete1 || !isApiCallComplete2 || !isApiCallComplete3) && !apiErrorRequireReload && (
                <PageLoader />
            )}

            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.appBarTitle}>
                        BBNL
                    </Typography>
                    <Button color="inherit" onClick={logout}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.innerContainer}>
                <Typography variant="h6" className={classes.title}>
                    Usage :
                </Typography>

                <Table>
                    <TableBody>
                        {[
                            {
                                label: "Upload",
                                value: dataUsage.upload,
                                suffix: "",
                                id: "usage1",
                                prefix: "",
                            },
                            {
                                label: "Download",
                                value: state.dataUsage.download,
                                suffix: "",
                                id: "usage2",
                                prefix: "",
                            },
                            {
                                label: "Total",
                                value: state.dataUsage.total,
                                suffix: "",
                                id: "usage3",
                                prefix: "",
                            },
                            {
                                label: "Balance",
                                value: state.dataUsage.balance.split(" remaining")[0],
                                suffix: "",
                                id: "usage6",
                                prefix: "",
                            },
                            { label: "From", value: state.dataUsage.fromDate, suffix: "", id: "usage4", prefix: "" },
                            { label: "To", value: state.dataUsage.toDate, suffix: "", id: "usage5", prefix: "" },
                        ].map(({ id, label, value, suffix, prefix }) => (
                            <TableRow key={id}>
                                <TableCell>
                                    <Typography variant="body2">{label} :</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body2" color="textPrimary">
                                        {!value || value === "" ? "-" : `${prefix} ${value} ${suffix}`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Typography variant="h6" className={`${classes.title} ${classes.title2}`}>
                    Plan Details :
                </Typography>

                <Table>
                    <TableBody>
                        {[
                            {
                                label: "Plan",
                                value: payments[0]?.planname || "",
                                suffix: "",
                                id: "planDetails1",
                                prefix: "",
                            },
                            {
                                label: "Paid Amt",
                                value: payments[0]?.paid_amt || "",
                                suffix: "",
                                id: "planDetails2",
                                prefix: "₹",
                            },
                            {
                                label: "Expiry Date",
                                value: planExpiration.date,
                                suffix: "",
                                id: "planDetails3",
                                prefix: "",
                            },
                            {
                                label: "Validity",
                                value: planExpiration.noofDays,
                                suffix: "Days",
                                id: "planDetails4",
                                prefix: "",
                            },
                        ].map(({ id, label, value, suffix, prefix }) => (
                            <TableRow key={id}>
                                <TableCell align="center">
                                    <Typography variant="body2">{label} :</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body2" color="textPrimary">
                                        {!value || value === -1 ? "-" : `${prefix} ${value} ${suffix}`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Typography variant="h6" className={`${classes.title} ${classes.title2}`}>
                    Payment History :
                </Typography>
                {payments.length === 0 && (
                    <Typography variant="body1" align="center" className={`${classes.title} ${classes.title2}`}>
                        No Payments Yet!
                    </Typography>
                )}

                {payments.map((payment) => {
                    return (
                        <Card className={classes.card} key={payment.id}>
                            <Table>
                                <TableBody>
                                    {[
                                        {
                                            label: "Plan",
                                            value: payment.planname,
                                            suffix: "",
                                            id: "payments1" + payment.id,
                                            prefix: "",
                                        },
                                        {
                                            label: "Payment Date",
                                            value: payment.payment_date,
                                            suffix: "",
                                            id: "payments2" + payment.id,
                                            prefix: "",
                                        },
                                        {
                                            label: "Bill Number",
                                            value: payment.bill_num,
                                            suffix: "",
                                            id: "payments3" + payment.id,
                                            prefix: "",
                                        },
                                        {
                                            label: "Price",
                                            value: payment.plan_rate
                                                ? parseFloat(payment.plan_rate).toFixed(2)
                                                : payment.plan_rate,
                                            suffix: "",
                                            id: "payments4" + payment.id,
                                            prefix: "₹",
                                        },
                                        {
                                            label: "Tax",
                                            value: payment.plan_rate
                                                ? (parseInt(payment.plan_rate) * 0.18).toFixed(2)
                                                : -1,
                                            suffix: "",
                                            id: "payments5" + payment.id,
                                            prefix: "₹",
                                        },
                                        {
                                            label: "Total",
                                            value: payment.plan_rate
                                                ? (parseInt(payment.plan_rate) * 1.18).toFixed(2)
                                                : -1,
                                            suffix: "",
                                            id: "payments6" + payment.id,
                                            prefix: "₹",
                                        },
                                    ].map(({ id, label, value, suffix, prefix }) => (
                                        <TableRow key={id}>
                                            <TableCell>
                                                <Typography variant="body2">{label} :</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" color="textPrimary">
                                                    {!value || value === -1 ? "-" : `${prefix} ${value} ${suffix}`}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Card>
                    );
                })}
                <MadeWithLove />
            </div>
        </Fragment>
    );
};
export default withStyles(Styles)(DashboardPage);
