import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme: Theme) =>
    createStyles({
        container: {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: theme.zIndex.appBar + 1,
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(2px)",
            flexDirection: "column",
        },
        overflowHidden: {
            overflow: "hidden",
        },
        button: {
            margin: "16px auto",
        },
    });

export default Styles;
