import { Field, FieldProps } from "formik";
import React from "react";

type Props<TComponentProps> = {
    name: string;
    Component: React.ComponentType<TComponentProps>;
} & TComponentProps;

const FormikField = <T extends Record<never, never>>({ Component, ...restProps }: Props<T>) => (
    <Field name={restProps.name}>
        {({ field }: FieldProps) => {
            return <Component {...field} {...(restProps as T)} />;
        }}
    </Field>
);

export default FormikField;
