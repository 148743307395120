export const ProxyURL = "https://cors-escaper.austonpramodh.workers.dev/";

export const BaseURL = "https://bbnlnetmon.bbnl.in";

export const BaseURL2 = "https://payurbills.co.in";

export const LoginRoute = "/bbnlcustapi/login/";

export const checkOtpRoute = "/bbnlcustapi/checkOTP/";

export const dataUsageRoute = "/best2/General/overallAvgUsageReport";

export const planExpirationRoute = "/prod/Apis/planExpiration/";

export const paymentHistoryRoute = "/prod/Apis/takebill/";
