import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme: Theme) =>
    createStyles({
        innerContainer: {
            padding: 24,
            display: "flex",
            flexDirection: "column",
        },
        appBarTitle: {
            flexGrow: 1,
        },
        title: {
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: 8,
        },
        title2: {
            marginTop: 24,
        },
        card: {
            marginTop: 8,
            marginBottom: 8,
        },
    });

export default Styles;
