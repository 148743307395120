import { useState } from "react";

const useSetState = <T>(initialState: T): [T, (newState: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
    const [state, regularSetState] = useState<T>(initialState);

    const setState = (newState: Partial<T> | ((prevState: T) => Partial<T>)) => {
        if (typeof newState === "function") {
            regularSetState((prevState) => {
                return { ...prevState, ...newState(prevState) };
            });
            return;
        }

        regularSetState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    return [state, setState];
};

export default useSetState;
