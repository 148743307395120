import { createStyles, Theme } from "@material-ui/core/styles";
const Styles = (_theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 8,
            flexDirection: "column",
        },
        icon: {
            fill: "#FE251B",
        },
    });

export default Styles;
