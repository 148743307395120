import React from "react";
import { Typography, Link } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
// import { Heart } from "mdi-material-ui";

import Styles from "./index.Styles";

const MadeWithLove: React.SFC<WithStyles<typeof Styles>> = ({ classes }) => (
    <Typography variant="overline" className={classes.root}>
        MADE WITH ❤ BY &nbsp;
        <Link color="inherit" href="https://www.auston.dev">
            AUSTON PRAMODH BARBOZA
        </Link>
    </Typography>
);

export default withStyles(Styles)(MadeWithLove);
