import qs from "qs";

import Router from "../router";
import { planExpirationRoute } from "../ApiRoutes";

export interface PlanExpirationResponseInterface {
    date: string;
    noofDays: number;
    status: { err_code: number; err_msg: string };
}

export function planExpirationApi(customerId: string, operatorId: string) {
    return Router.request<PlanExpirationResponseInterface>({
        method: "post",
        url: planExpirationRoute,
        data: qs.stringify({
            custid: customerId,
            apiopid: operatorId,
        }),
    });
}
