export function setOperatorId(operatorId: string) {
    localStorage.setItem("operatorId", operatorId);
}

export function getOperatorId() {
    return localStorage.getItem("operatorId");
}

export function setOperatorName(operatorName: string) {
    localStorage.setItem("operatorName", operatorName);
}

export function getOperatorName() {
    return localStorage.getItem("operatorName");
}
